import React from 'react';
import s from './ErrorModal.module.css';
import img from '../../images/icon-error.svg';
import { useNavigate } from 'react-router-dom';

export default function ErrorModal({ onClose, questionClose }) {
  const navigator = useNavigate();
  return (
    <div className={s.overlay}>
      <div className={s.modal}>
        <button className={s.closeButton} onClick={onClose}></button>
        <img src={img} alt="Error" className={s.img} />
        <h3 className={s.title}>Что-то пошло не так...</h3>
        <p className={s.text}>
          Не волнуйтесь, в течение суток предложение появится в разделе “Мои
          предложения” и вы сможете им воспользоваться.
        </p>
        <button
          className={s.button}
          onClick={() => {
            navigator('/');
            questionClose();
            onClose();
          }}
        >
          Хорошо
        </button>
      </div>
    </div>
  );
}
