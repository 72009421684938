import React from 'react';
import Header from '../../components/Header/Header';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import { useForm } from 'react-hook-form';
import { userStore } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { sendCodeToEmail } from '../../api/api';
import { linkToMain } from '../../api/api';

import s from './Auth.module.css';

export default function Auth({ twaMainLink }) {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const watchEmail = watch('email');
  const user = userStore();
  const updateEmail = userStore((state) => state.updateEmail);

  const onSubmit = (data) => {
    updateEmail(data.email);
    navigate('/auth-code');
    sendCodeToEmail(data.email)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className={s.auth}>
      <Header />
      <NavigationBar
        backLink={
          localStorage.getItem('back') === true
            ? localStorage.getItem('twa_main')
            : null
        }
      />
      <div className={s.content}>
        <h1 className={s.title}>
          {localStorage.getItem('email') &&
          localStorage
            .getItem('spice_id_list')
            ?.includes(localStorage.getItem('spice_id'))
            ? 'Укажите свой актуальный E-mail'
            : 'Добро пожаловать в Звёздную Пятницу в Q CLUB'}
        </h1>
        <p className={s.subtitle}>
          Перед тем, как начать, пожалуйста, подтвердите свой электронный адрес.
          Это поможет нам связаться с вами в случае победы в последующем
          конкурсе для доставки приза.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className={s.label}>
            E-mail
            <input
              className={s.input}
              type="text"
              placeholder="Введите E-mail"
              {...register('email', {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i,
                minLength: 1,
              })}
            />
          </label>

          {errors.email?.type === 'pattern' && (
            <p role="alert" className={s.errorText}>
              Некорректный формат E-mail
            </p>
          )}
          <button
            className={s.button}
            type="submit"
            disabled={errors.email ? true : false || watchEmail ? false : true}
          >
            Подтвердить E-mail
          </button>
        </form>
      </div>
    </div>
  );
}
