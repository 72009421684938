import React, { useEffect } from 'react';
import Header from '../../components/Header/Header';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../store/store';
import { linkToMain } from '../../api/api';
import { SPICE_ID } from '../../api/api';

import s from './AuthActual.module.css';

export default function AuthActual() {
  const navigator = useNavigate();
  const user = userStore();

  useEffect(() => {
    localStorage.setItem('back', true);
  }, []);

  const email = localStorage.getItem('email');

  return (
    <div className={s.authActual}>
      <Header />
      <NavigationBar backLink={localStorage.getItem('twa_main')} />
      <div className={s.content}>
        <h2 className={s.title}>
          Ваш E-mail <br />
          актуален?
        </h2>
        <p className={s.subtitle}>{email}</p>
        <button
          className={s.primeButton}
          onClick={() => {
            if (localStorage.getItem('spice_id_list')) {
              const array = [];
              const fullArray = array.concat(
                localStorage.getItem('spice_id_list')
              );
              fullArray.push(SPICE_ID);
              localStorage.setItem('spice_id_list', fullArray);
              user.proveEmail();
            } else {
              console.log(211);
              const array = [];
              array.push(SPICE_ID);
              localStorage.setItem('spice_id_list', array);
              user.proveEmail();
            }
            navigator('/');
          }}
        >
          Да
        </button>
        <button
          className={s.secondaryButton}
          onClick={() => {
            navigator('/auth');
          }}
        >
          Нет, изменить E-mail
        </button>
      </div>
    </div>
  );
}
