import img1 from '../images/questions/1.png';
import img2 from '../images/questions/2.png';
import img3 from '../images/questions/3.png';
import img4 from '../images/questions/4.png';
import img5 from '../images/questions/5.png';
import img6 from '../images/questions/6.png';
import img7 from '../images/questions/7.png';
import img8 from '../images/questions/8.png';
import img9 from '../images/questions/9.png';
import img10 from '../images/questions/10.png';

export const questions = [
  {
    id: 1,
    question:
      'Космос находится в 50 км от Земли, а на каком расстоянии наш чат-бот IQOS Россия в Telegram?',
    variants: [
      'На вытянутой руке',
      'Три свайпа',
      'Один клик',
      'Все варианты правильные',
    ],
    rightVariant: 'Все варианты правильные',
    correctTitle: 'Все правильно!',
    wrongTitle: 'Вы были близки!',
    subtitle: 'Наш чат-бот IQOS Россия в Telegram всегда под рукой',
    offer: 'Ваш код на -60% на пластиковые стичницы',
    promocode: true,
    isLink: true,
    isShop: true,
    img: img1,
    backgroundColor: 'blue',
    buttonText: 'Использовать',
    link: 'https://www.iqos.ru/shop/main/accessories/stichnitsy/stichnitsa/?oid=213903&utm_source=telegram&utm_medium=button-offer-stichnitsa&utm_campaign=stars-campaign',
  },
  {
    id: 2,
    question: 'Как вы думаете, сколько в России фирменных магазинов Q STORE?',
    variants: [
      'Как звезд во Вселенной',
      'Главное, что по пути домой',
      'Штук 100?',
      'Более 400',
    ],
    rightVariant: 'Более 400',
    correctTitle: 'Добро пожаловать!',
    wrongTitle: 'Вы были близки!',
    subtitle: 'В России уже более 400 фирменных магазинов Q STORE',
    offer:
      'Вы открыли 2 звезды из 10! А значит, вы уже можете участвовать в конкурсе и побороться за сертификат OZON. Следите за обновлениями, после завершения «Звёздной Пятницы» вы получите приглашение в конкурс.',
    bottomText: false,
    img: img2,
    backgroundColor: 'gold',
    buttonText: 'Отлично',
  },
  {
    id: 3,
    question: 'Сколько лет IQOS в России?',
    variants: ['10', 'Почти 10 (9)', 'Лет 5?', 'Как будто бы всегда'],
    rightVariant: 'Почти 10 (9)',
    correctTitle: 'Все правильно!',
    wrongTitle: 'Вы были близки!',
    subtitle: 'IQOS в России уже 9 лет',
    offer: 'Ваш код на -60% на любую панель для IQOS или lil SOLID',
    promocode: true,
    isLink: true,
    isShop: true,
    img: img3,
    backgroundColor: 'blue',
    buttonText: 'Использовать',
    link: 'https://www.iqos.ru/shop/main/accessories/panels/?utm_source=telegram&utm_medium=button-offer-panels&utm_campaign=stars-campaign',
  },
  {
    id: 4,
    question:
      'Представьте, что вы оказались на другой планете. Что точно будет в вашей сумке?',
    variants: [
      'Зарядка для устройства',
      'Чистящие палочки',
      'Аксессуары для устройства',
      'Полотенце',
      'Все из перечисленного',
    ],
    rightVariant: 'Все из перечисленного',
    correctTitle: 'Все правильно!',
    wrongTitle: 'Вы были близки!',
    subtitle:
      'На другой планете вам точно пригодятся аксессуары и зарядка для устройства, чистящие палочки и полотенце',
    offer: '+1 дополнительное предложение Q CLUB на выбор',
    bottomText:
      'Выбрать дополнительное предложение вы можете в мини-приложении Q CLUB в разделе "Мои предложения"',
    img: img4,
    backgroundColor: 'blue',
    buttonText: 'Перейти к моим предложениям',
  },
  {
    id: 5,
    question:
      'Вы уже знаете сколько лет IQOS в России, а сколько лет нашей солнечной системе?',
    variants: [
      '1 миллиард',
      '4.57 миллиарда',
      'Нет такого числа',
      'Хочу узнать правильный ответ',
    ],
    rightVariant: '4.57 миллиарда',
    correctTitle: 'На пять с плюсом!',
    wrongTitle: 'Вы были близки!',
    subtitle: 'Нашей солнечной системе приблизительно 4.57 миллиарда лет',
    offer:
      'Вы открыли 5 звезд из 10! А это значит, что вы уже можете поучаствовать в конкурсе и получить Яндекс Станцию Макс с Алисой. Следите за обновлениями, после завершения «Звёздной Пятницы» вы получите приглашение в конкурс.',
    bottomText: false,
    img: img5,
    backgroundColor: 'gold',
    buttonText: 'Отлично',
  },
  {
    id: 6,
    question:
      'Из одного из наших фирменных магазинов видно северное сияние. Как думаете, где он находится?',
    variants: ['Мурманск', 'Владивосток', 'Магадан', 'Петрозаводск'],
    rightVariant: 'Мурманск',
    correctTitle: 'Все правильно!',
    wrongTitle: 'Вы были близки!',
    subtitle:
      'Из фирменного магазина Q STORE в Мурманске видно северное сияние',
    offer: 'Ваш код на -60% на набор 10 палочек для чистки',
    promocode: true,
    isLink: true,
    isShop: true,
    img: img6,
    backgroundColor: 'blue',
    buttonText: 'Использовать',
    link: 'https://www.iqos.ru/shop/main/accessories/palochki_dlya_chistki_iqos_10_shtuk/?oid=221078&utm_source=telegram&utm_medium=button-offer-cleaning-sticks&utm_campaign=stars-campaign',
  },
  {
    id: 7,
    question:
      'Как вы думаете, сколько совершеннолетних пользователей систем нагревания в России?',
    variants: ['Более 4 млн', '1 500 000 триллиона', '2 септиллиона'],
    rightVariant: 'Более 4 млн',
    correctTitle: 'Целая неделя открытий!',
    wrongTitle: 'Вы были близки!',
    subtitle:
      'В России системами нагревания пользуются уже более 4 млн совершеннолетних пользователей',
    offer:
      'Вау, 7 из 10 звезд открыто! А это значит, что помимо сертификатов OZON и Яндекс Станции Макс с Алисой, вы получите возможность выиграть iPhone 16 в конкурсе после завершения «Звёздной Пятницы». Открывайте звезды до 8 декабря и следите за обновлениями.',
    bottomText: false,
    img: img7,
    backgroundColor: 'gold',
    buttonText: 'Отлично',
  },
  {
    id: 8,
    question: 'Какие звезды самые главные в созвездии IQOS?',
    variants: [
      'Наука',
      'Продукт',
      'Сервисы',
      'Совершеннолетние потребители',
      'Все из перечисленного',
    ],
    rightVariant: 'Все из перечисленного',
    correctTitle: 'Все правильно!',
    wrongTitle: 'Вы были близки!',
    subtitle:
      'Самые главные звезды в созвездии IQOS – это наука, продукт, сервисы и, конечно, совершеннолетние потребители',
    offer:
      'Ваш код на -60% на любой чехол для IQOS, lil SOLID или lil HYBRID 3.0',
    promocode: true,
    isLink: true,
    isShop: true,
    img: img8,
    backgroundColor: 'blue',
    buttonText: 'Использовать',
    link: 'https://www.iqos.ru/shop/main/accessories/covers-clips-cases/?utm_source=telegram&utm_medium=button-offer-cases&utm_campaign=stars-campaign',
  },
  {
    id: 9,
    question:
      'Годовщина бренда IQOS 23 ноября. Что именно случилось в этот день?',
    variants: [
      'Открыли первый фирменный магазин',
      'Запустили официальный сайт',
      'Запустили чат-бот в Telegram',
      'Создали сообщество ВКонтакте',
    ],
    rightVariant: 'Открыли первый фирменный магазин',
    correctTitle: 'Все правильно!',
    wrongTitle: 'Вы были близки!',
    subtitle:
      'Первый фирменный магазин IQOS был открыт 23 ноября 2015 года, и именно эту дату мы считаем нашим днём рождения',
    offer: 'Ваш код на -60% на персонализированную панель для IQOS 3 DUOS',
    promocode: true,
    isLink: true,
    img: img9,
    backgroundColor: 'blue',
    buttonText: 'Использовать',
    link: 'https://www.iqos.ru/print-your-art/?utm_source=telegram&utm_medium=button-offer-pers-panels&utm_campaign=stars-campaign',
  },
  {
    id: 10,
    question:
      'А вы знали, что у Юпитера 79 спутников? Вот это да! А сколько специальных предложений доступно участникам Q CLUB в течение сезона?',
    variants: ['2', '3', '5', '79'],
    rightVariant: '2',
    correctTitle: 'Все правильно!',
    wrongTitle: 'Вы были близки!',
    subtitle:
      'В течение сезона участникам Q CLUB доступно 2 специальных предложения, помимо дополнительных, которые можно получить за выполнение заданий',
    offer: 'Ваш абонемент на профессиональную чистку',
    promocode: true,
    bottomTextWithLink: false,
    bottomText:
      'Код будет доступен в мини-приложении Q CLUB в разделе «Мои предложения». Код действует до 31.03.2025',
    img: img10,
    backgroundColor: 'blue',
    buttonText: 'Отлично',
  },
];
