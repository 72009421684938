import React, { useState, useEffect } from 'react';
import s from './Card.module.css';
import imgDisabled from '../../images/card-star-disabled.svg';
import imgPast from '../../images/star-gray.svg';
import imgMain from '../../images/card-star.svg';
import imgOpenedBlue from '../../images/card-opened-blue.png';
import imgOpenedYellow from '../../images/card-opened-yellow.png';
import { isSameDay, isPast, isTomorrow, addDays } from 'date-fns';
import { userStore } from '../../store/store';
import { offers } from '../../mockups/boxes';
import RepeatedOpenModal from '../RepeatedOpenModal/RepeatedOpenModal';

export default function Card({ onClick, date, currentTime }) {
  const { rewards } = userStore();

  const [day, setDay] = useState();
  const [text, setText] = useState();
  const [color, setColor] = useState();
  const [image, setImage] = useState();
  const [status, setStatus] = useState('default');
  const [openedModal, setOpenedModal] = useState();
  const [tomorrow, setTomorrow] = useState();

  useEffect(() => {
    if (currentTime) {
      if (isSameDay(date, new Date(currentTime))) {
        setDay('today');
      } else if (date.getTime() < new Date(currentTime).getTime()) {
        setDay('past');
      } else {
        setDay('future');
      }
      const tomorrow = addDays(new Date(currentTime), 1);
      setTomorrow(tomorrow);
    }
  }, [currentTime, day]);

  useEffect(() => {
    setBoxImage();
  }, [day, status]);

  const closeModal = () => {
    setOpenedModal(false);
  };

  const setBoxImage = () => {
    if (color) {
      if (color === 'blue') {
        setImage(imgOpenedBlue);
      } else {
        setImage(imgOpenedYellow);
      }
    } else if (day === 'past') {
      setImage(imgPast);
    } else if (day === 'today') {
      setImage(imgMain);
    } else {
      setImage(imgDisabled);
    }
  };

  const setBoxText = () => {
    rewards.forEach((reward) => {
      if (isSameDay(date, new Date(reward.received_at))) {
        const item = offers.find((item) => item.id === reward.reward_number);
        setColor(item.color);
        setText(item.offer);
      }
    });
  };

  useEffect(() => {
    rewards?.forEach((reward) => {
      if (isSameDay(date, new Date(reward.received_at))) {
        setStatus('opened');
        setBoxText();
      }
    });
  }, [rewards]);

  return (
    <div className={s.card}>
      <p className={s.date}>{`${date.getDate()}.${date.getMonth() + 1}`}</p>
      <img
        src={image}
        alt=""
        className={s.img}
        onClick={() => {
          if (status === 'opened') {
            setOpenedModal(true);
          }
        }}
      />

      {day === 'today' && status === 'default' && (
        <button className={s.button} onClick={onClick}>
          Открыть
        </button>
      )}

      {day === 'past' && status === 'opened' && (
        <p className={`${s.text} ${color === 'yellow' ? s.yellow : s.blue}`}>
          {text}
        </p>
      )}

      {addDays(new Date(date).setHours(0, 0, 0, 0), 0).getTime() <
        tomorrow?.getTime() &&
        tomorrow?.getTime() <
          addDays(new Date(date).setHours(0, 0, 0, 0), 1).getTime() && (
          <p className={`${s.text} ${s.tomorrow}`}>Станет доступно завтра</p>
        )}

      {day === 'past' && status === 'default' && (
        <p className={`${s.text} ${s.missed}`}>Упс, задание пропущено</p>
      )}

      {day === 'today' && status === 'opened' && (
        <p className={`${s.text} ${color === 'yellow' ? s.yellow : s.blue}`}>
          {text}
        </p>
      )}
      {openedModal && <RepeatedOpenModal color={color} onClose={closeModal} />}
    </div>
  );
}
