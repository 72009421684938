import React from 'react';
import s from './Header.module.css';
import eighteenPlus from '../../images/18+.svg';
import eighteenPlusDark from '../../images/18+_light.svg';

export default function Header({ mode }) {
  return (
    <div className={`${s.header} ${mode === 'light' ? s.light : ''}`}>
      {mode === 'light' ? (
        <img src={eighteenPlusDark} alt="18+" />
      ) : (
        <img src={eighteenPlus} alt="18+" />
      )}
      <p className={`${s.text} ${mode === 'light' ? s.dark : ''}`}>
        ВАЖНО: НЕ ИСКЛЮЧАЕТ РИСКИ. АЭРОЗОЛЬ СОДЕРЖИТ НИКОТИН, ВЫЗЫВАЮЩИЙ
        ЗАВИСИМОСТЬ
      </p>
    </div>
  );
}
