import axios from 'axios';

if (typeof window !== 'undefined') {
  const tg = window.Telegram.WebApp;
  tg.expand();
  tg.ready();
}

function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
}

const devHost = 'https://iqosmeetingbot-test.cleverbots.ru';
const prodHost = 'https://iqosmeetingbot.cleverbots.ru';

const searchParams = new URLSearchParams(window.location.search);

// get data from url
let USER_ID = getUrlParameter('user_id') || '0';
let CHAT_ID = getUrlParameter('chat_id') || '0';

let NAMESPACE =
  window.location.hostname === 'iqos-black-friday-game.cleverbots.ru'
    ? 'prod'
    : 'dev';
const SPICE_ID = searchParams.get('spice_id');
if (SPICE_ID) {
  localStorage.setItem('spice_id', SPICE_ID);
}

const emailInParams = searchParams.get('email');

const linkToOffers =
  NAMESPACE === 'dev'
    ? `https://iqos-front-main.ktsdev.ru/tg?data=${localStorage.getItem(
        'twa_offers'
      )}&stage=dev2`
    : `https://iqos.ru-prod2.kts.studio/tg?data=${localStorage.getItem(
        'twa_offers'
      )}`;

const linkToMain =
  NAMESPACE === 'dev'
    ? `https://iqos-front-main.ktsdev.ru/tg?data=${localStorage.getItem(
        'twa_main'
      )}&stage=dev2`
    : `https://iqos.ru-prod2.kts.studio/tg/tg?data=${localStorage.getItem(
        'twa_main'
      )}`;

// get url prod or dev based on namespace
const URL_API = NAMESPACE === 'prod' ? prodHost : devHost;

// send data to our django service
async function sendEventAnalitic(nameEvent) {
  const jsonSend = {
    user_id: USER_ID,
    event: nameEvent,
    spice_id: SPICE_ID,
  };
  console.log(jsonSend);

  try {
    const response = await fetch(URL_API + 'api/actions/q_club/webbot', {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify(jsonSend),
    });

    if (!response.ok) {
      // get error text

      const errorText = await response.text();

      var bot_creds = {
        prod: '-1001473099971', // owner Hassan
        dev: '-1001980910693', // owner Hassan
      };

      // send it to telegram error group
      var bot_token = '1812237208:AAGrOTTyRXJkkZheZJQ7Hd5pLt4_gPY40YA'; // owner Hassan
      var text =
        'From webbot: ' +
        errorText +
        ' \n\nUSER_ID: ' +
        USER_ID +
        ' \n\nSPICE_ID: ' +
        SPICE_ID;
      var url =
        'https://api.telegram.org/bot' +
        bot_token +
        '/sendMessage?chat_id=' +
        bot_creds[NAMESPACE] +
        '&parse_mode=HTML&text=' +
        text;
      await fetch(url, {
        method: 'GET',
      });

      console.error('Ошибка при отправке запроса:', errorText);
      return;
    }
  } catch (error) {
    var bot_creds = {
      prod: '-1001473099971', // owner Hassan
      dev: '-1001980910693', // owner Hassan
    };

    // send it to telegram error group
    var bot_token = '1812237208:AAGrOTTyRXJkkZheZJQ7Hd5pLt4_gPY40YA'; // owner Hassan
    var text =
      'From webbot: ' +
      error +
      ' \n\nUSER_ID: ' +
      USER_ID +
      ' \n\nSPICE_ID: ' +
      SPICE_ID;
    var url =
      'https://api.telegram.org/bot' +
      bot_token +
      '/sendMessage?chat_id=' +
      bot_creds[NAMESPACE] +
      '&parse_mode=HTML&text=' +
      text;
    await fetch(url, {
      method: 'GET',
    });

    console.error('Ошибка при отправке запроса:', error);
  }
}

const devToken = 'vqZLUQDwpk7JGEuXhFKrCV5HmPf8RTyx';
const prodToken = 'b2HXnVkFsMdGR9KW4Cz8LgDQecu5UNBa';
const TOKEN = NAMESPACE === 'prod' ? prodToken : devToken;

const getRewards = () => {
  return axios.get(`${URL_API}/api/actions/black_friday/reward/list`, {
    params: {
      spice_id: SPICE_ID || localStorage.getItem('spice_id'),
    },
    headers: { Authorization: `Token ${TOKEN}` },
  });
};

const postRewards = () => {
  return axios.post(
    `${URL_API}/api/actions/black_friday/reward/detail`,
    {
      spice_id: SPICE_ID || localStorage.getItem('spice_id'),
      scope: 'qclub',
    },
    { headers: { Authorization: `Token ${TOKEN}` } }
  );
};

const sendCodeToEmail = (email) => {
  return axios.post(
    `${URL_API}/api/actions/black_friday/email/address`,
    {
      spice_id: SPICE_ID || localStorage.getItem('spice_id'),
      email: email,
    },
    { headers: { Authorization: `Token ${TOKEN}` } }
  );
};

const sendApproveCode = (email, code) => {
  return axios.post(
    `${URL_API}/api/actions/black_friday/email/code`,
    {
      spice_id: SPICE_ID || localStorage.getItem('spice_id'),
      email: email,
      code: code,
    },
    { headers: { Authorization: `Token ${TOKEN}` } }
  );
};

const getCurrentTime = () => {
  return axios.get(`${URL_API}/api/get_current_time`, {
    headers: { Authorization: `Token ${TOKEN}` },
  });
};

export {
  sendEventAnalitic,
  getUrlParameter,
  getRewards,
  postRewards,
  sendCodeToEmail,
  sendApproveCode,
  linkToOffers,
  linkToMain,
  SPICE_ID,
  emailInParams,
  NAMESPACE,
  getCurrentTime,
};
