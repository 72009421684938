import React, { useState } from 'react';
import s from './Question.module.css';
import Header from '../Header/Header';
import img from '../../images/star-answer.svg';
import AnswerButton from '../AnswerButton/AnswerButton';
import { Link } from 'react-router-dom';
import { getRewards, linkToOffers, postRewards } from '../../api/api';
import { userStore } from '../../store/store';
import Loader from '../Loader/Loader';

import buttonLoader from '.././../images/button-loader.svg';
import ErrorModal from '../ErrorModal/ErrorModal';

export default function Question({ question, onClose, twaOfferLink }) {
  const [checked, setChecked] = useState();
  const [status, setStatus] = useState(true);
  const [correct, setCorrect] = useState();
  const [promocode, setPromocode] = useState();
  const [hash, setHash] = useState();
  const [error, setError] = useState();
  const [errorModal, setErrorModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);

  const updateRewards = userStore((state) => state.updateRewards);

  const onSubmit = () => {
    setLoader(true);
    if (checked === question.rightVariant) {
      setCorrect(true);
    } else {
      setCorrect(false);
    }
    postRewards()
      .then((responce) => {
        const data = responce.data.data;
        if (data.need_promocode && data.promocode) {
          setPromocode(data.promocode);
          setHash(data.hash_token);
        } else if (data.need_promocode && !data.promocode) {
          setErrorModal(true);
        }
      })
      .then(() => {
        setLoader(false);
        toggleStatus();
      })
      .catch((error) => {
        if (error.status === 500) {
          postRewards().then((responce) => {
            const data = responce.data.data;
            if (data.need_promocode && data.promocode) {
              setPromocode(data.promocode);
              setHash(data.hash_token);
            } else if (data.need_promocode && !data.promocode) {
              setErrorModal(true);
              setLoader(false);
              setError(true);
            }
          });
        } else {
          setError(true);
          setErrorModal(true);
          setLoader(false);
        }
      })
      .finally(
        setTimeout(() => {
          getRewards()
            .then((responce) => {
              const rewards = responce.data.data;
              updateRewards(rewards);
            })
            .catch((err) => {
              console.log(err);
            });
        }, 2000)
      );
  };

  const toggleStatus = () => {
    setStatus(!status);
  };

  const closeErrorModal = () => {
    setErrorModal(false);
  };

  return (
    <div className={s.question}>
      {status ? (
        <div className={s.container}>
          <button
            className={s.closeButton}
            onClick={onClose}
            disabled={loader}
          ></button>
          <img src={img} alt="" className={s.img} />
          <h2 className={s.title}>{question?.question}</h2>
          <p className={s.subtitle}>Выберите 1 вариант ответа</p>
          <div className={s.answerList}>
            {question?.variants.map((answer, index) => {
              return (
                <AnswerButton
                  id={index}
                  name="qwe"
                  labelText={answer}
                  checked={checked === answer}
                  onChange={() => setChecked(answer)}
                  key={index}
                />
              );
            })}
          </div>
          <button
            className={s.button}
            onClick={onSubmit}
            disabled={!checked || loader}
          >
            {loader ? (
              <img src={buttonLoader} className={s.buttonLoader} />
            ) : error ? (
              'Ошибка'
            ) : (
              'Ответить'
            )}
          </button>
          <Header mode="light" />
        </div>
      ) : (
        <div
          className={`${s.answerContainer} ${
            question?.backgroundColor === 'gold' ? s.gold : ''
          }`}
        >
          <div className={s.content}>
            <button className={s.closeButton} onClick={onClose}></button>
            <img src={question?.img} alt="123" className={s.answerImg} />
            <h2 className={s.answerTitle}>
              {correct ? question?.correctTitle : question?.wrongTitle}
            </h2>
            <p className={s.answerSubtitle}>{question?.subtitle}</p>
            <p
              className={`${s.promocodeText} ${
                question?.backgroundColor === 'gold' ? s.gold : ''
              }`}
            >
              {question?.offer}
            </p>
            {question?.promocode && (
              <div className={s.promocodeContainer}>
                <p className={s.promocode}>{promocode}</p>
                <button
                  className={s.copyButton}
                  onClick={() => {
                    navigator.clipboard.writeText(promocode);
                  }}
                ></button>
              </div>
            )}
            <button
              className={s.button}
              onClick={() => {
                if (question?.buttonText === 'Использовать') {
                  setLoaderModal(true);
                  const link =
                    question.link + `&T=${hash}&promocode=${promocode}`;

                  window.location.href = link;
                  setTimeout(() => {
                    setLoaderModal(false);
                  }, 4000);
                } else if (
                  question?.buttonText === 'Перейти к моим предложениям'
                ) {
                  window.location.href = localStorage.getItem('twa_offer');
                } else {
                  onClose();
                }
              }}
            >
              {question?.buttonText}
            </button>
            {question?.isLink && (
              <p className={s.text}>
                Предложением можно воспользоваться{' '}
                {question?.isShop && 'в фирменных магазинах Q STORE или '}на
                сайте{' '}
                <Link className={s.link} to="https://www.iqos.ru/">
                  iqos.ru
                </Link>
                , пока товары есть в наличии. Код действует до 31.12.2024
              </p>
            )}
            {question?.bottomText && (
              <p className={s.text}>{question?.bottomText}</p>
            )}
          </div>
          <Header mode="light" />
        </div>
      )}
      {errorModal && (
        <ErrorModal onClose={closeErrorModal} questionClose={onClose} />
      )}
      {loaderModal && <Loader />}
    </div>
  );
}
