import React from 'react';
import s from './RepeatedOpenModal.module.css';
import Header from '../Header/Header';
import blueStar from '../../images/blue-star.png';
import goldStar from '../../images/gold-star.png';
import { Link } from 'react-router-dom';

export default function RepeatedOpenModal({ onClose, color }) {
  return (
    <div className={s.overlay}>
      <div className={s.modal}>
        <button className={s.closeButton} onClick={onClose}></button>
        <img
          src={color === 'yellow' ? goldStar : blueStar}
          alt="Star"
          className={s.img}
        />
        <h3 className={s.title}>
          {color === 'yellow'
            ? 'Вау, здесь открыта не просто звезда! Это ваше приглашение в конкурс «Звёздная Пятница», который начнется 13 декабря'
            : 'Вау, вы уже открыли эту звезду и получили специальное предложение, спрятанное в ней'}
        </h3>
        <p className={s.text}>
          {color === 'yellow'
            ? 'Проверить другие специальные предложения можно по кнопке ниже'
            : 'Проверить это предложение можно по кнопке ниже'}
        </p>
        <Link to={localStorage.getItem('twa_offer')} className={s.button}>
          В мои предложения Q CLUB
        </Link>
      </div>
      <Header mode="light" />
    </div>
  );
}
