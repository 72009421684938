import React from 'react';
import Header from '../Header/Header';
import s from './FinalModal.module.css';
import img from '../../images/modal-final.png';
import modalImg1 from '../../images/mini-modal-1.png';
import modalImg2 from '../../images/mini-modal-2.png';
import modalImg3 from '../../images/mini-modal-3.png';

export default function FinalModal({ onClose, rewards }) {
  return (
    <div className={s.modal}>
      <div className={s.container}>
        <div className={s.content}>
          <button className={s.closeButton} onClick={onClose}></button>
          <img src={img} alt="" className={s.img} />
          <h2 className={s.title}>Как блистательно!</h2>
          <p className={s.subtitle}>
            Спасибо за участие в «Звёздной Пятнице в Q CLUB»
          </p>
          <p className={s.text}>
            Вы можете найти коды на полученные предложения в разделе «Мои
            предложения».
          </p>
          {rewards.length > 1 && (
            <div className={s.miniModal}>
              {rewards.length > 1 && rewards.length < 5 && (
                <img src={modalImg1} alt="" className={s.modalImg} />
              )}

              {rewards.length >= 5 && rewards.length <= 6 && (
                <img src={modalImg2} alt="" className={s.modalImg} />
              )}

              {rewards.length >= 7 && (
                <img src={modalImg3} alt="" className={s.modalImg} />
              )}

              <p className={s.miniText}>
                Благодаря вашей активности, вы можете поучаствовать в конкурсе.
                Приз - сертификат OZON{' '}
                {rewards.length > 4 &&
                  rewards.length < 7 &&
                  'или Яндекс Станция Макс с Алисой'}{' '}
                {rewards.length >= 7 &&
                  ', Яндекс Станция Макс с Алисой или iPhone 16'}
                . Ожидайте приглашения в чат-боте IQOS Россия в Telegram.
              </p>
            </div>
          )}
        </div>
      </div>
      <Header mode="light" />
    </div>
  );
}
