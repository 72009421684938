import { addDays } from 'date-fns';
import { startDate } from './constants';

const boxes = [
  {
    id: 0,
    date: startDate,
  },
  {
    id: 1,
    date: addDays(startDate, 1),
  },
  {
    id: 2,
    date: addDays(startDate, 2),
  },
  {
    id: 3,
    date: addDays(startDate, 3),
  },
  {
    id: 4,
    date: addDays(startDate, 4),
  },
  {
    id: 5,
    date: addDays(startDate, 5),
  },
  {
    id: 6,
    date: addDays(startDate, 6),
  },
  {
    id: 7,
    date: addDays(startDate, 7),
  },
  {
    id: 8,
    date: addDays(startDate, 8),
  },
  {
    id: 9,
    date: addDays(startDate, 9),
  },
];

const offers = [
  {
    id: 1,
    offer: '-60% на пластиковую стичницу',
    color: 'blue',
  },
  { id: 2, offer: 'Приглашение к участию в конкурсе', color: 'yellow' },
  {
    id: 3,
    offer: '-60% на любую панель',
    color: 'blue',
  },
  { id: 4, offer: '+1 предложение в Q CLUB', color: 'blue' },
  { id: 5, offer: 'Приглашение к участию в конкурсе', color: 'yellow' },
  {
    id: 6,
    offer: '-60% на палочки для чистки',
    color: 'blue',
  },
  { id: 7, offer: 'Участие в розыгрыше iPhone 16', color: 'yellow' },
  {
    id: 8,
    offer: '-60% на любой чехол',
    color: 'blue',
  },
  { id: 9, offer: '-60% на персонализированную панель', color: 'blue' },
  {
    id: 10,
    offer: 'Абонемент на профессио-нальную чистку',
    color: 'blue',
  },
];

export { boxes, offers };
