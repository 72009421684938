import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import { Link, useNavigate } from 'react-router-dom';
import s from './Main.module.css';
import ribbon from '../../images/ribbon.png';
import Card from '../../components/Card/Card';
import Question from '../../components/Question/Question';
import { questions } from '../../mockups/questions';
import { dateStore, userStore } from '../../store/store';
import { intervalToDuration } from 'date-fns';
import { boxes } from '../../mockups/boxes';
import { getRewards, getCurrentTime } from '../../api/api';
import { startDate, finishDate } from '../../mockups/constants';

export default function Main() {
  const navigator = useNavigate();

  const [question, setQuestion] = useState();
  const [modalWithQuestion, setModalWithQuestion] = useState(false);
  const [duration, setDuration] = useState();
  const [currentTime, setCurrentTime] = useState();

  const { rewards, actualEmail } = userStore();
  const user = userStore();

  const updateRewards = userStore((state) => state.updateRewards);

  useEffect(() => {
    getCurrentTime()
      .then((responce) => {
        const data = responce.data.data;
        console.log(data);
        setCurrentTime(data.current_time * 1000);
      })
      .catch((error) => {
        console.error('Ошибка при получении времени:', error);
      });

    if (
      intervalToDuration({
        start: new Date(),
        end: finishDate,
      }).days
    ) {
      setDuration(
        intervalToDuration({
          start: new Date(),
          end: finishDate,
        }).days + 1
      );
    } else {
      setDuration(1);
    }
  }, []);

  const updateStartDate = dateStore((state) => state.updateStartDate);

  useEffect(() => {
    getRewards()
      .then((responce) => {
        const rewards = responce?.data?.data;
        updateRewards(rewards);
        const currentQuestion = questions.find(function (item) {
          return item.id === rewards?.length + 1;
        });
        setQuestion(currentQuestion || questions[0]);
      })
      .catch((err) => {
        console.log(err);
      });

    updateStartDate(startDate);
  }, []);

  const closeQuestion = () => {
    setModalWithQuestion(false);
  };

  const openQuestion = () => {
    setModalWithQuestion(true);
  };

  return (
    <div className={s.main}>
      <Header />
      <NavigationBar backLink={localStorage.getItem('twa_main')} />

      <div className={s.content}>
        <h1 className={s.title}>Звёздная Пятница в Q CLUB</h1>
        <p className={s.subtitle}>
          Каждый день вы можете открывать звёзды в «Звёздной Пятнице» и получать
          за это специальные предложения. Чем больше звёзд открыто, тем больше
          шансов получить доступ в конкурс с призами: сертификат OZON, Яндекс
          Станция Макс с Алисой и iPhone 16. Вперед к звездам! Полные правила по
          <Link
            className={s.link}
            to="https://www.iqos.ru/documents/#pravila-activnosti-zvezdnaya-pyatnitsa"
            target="_blank"
          >
            {' '}
            ссылке.
          </Link>
        </p>
        <p className={s.countdown}>
          {duration > 1 ? 'Осталось ' : 'Остался '}

          {`${duration} ${
            duration > 4 ? 'дней' : duration > 1 ? 'дня' : 'день'
          }`}
          <img src={ribbon} alt="Ribbon" className={s.ribbon} />
        </p>

        <div className={s.cards}>
          {boxes?.map((box) => {
            return (
              <Card
                onClick={openQuestion}
                date={box.date}
                key={box.date}
                opened={box.opened}
                box={box}
                rewards={rewards}
                currentTime={currentTime}
              />
            );
          })}
        </div>
      </div>
      {modalWithQuestion && (
        <Question question={question} onClose={closeQuestion} />
      )}
    </div>
  );
}
