import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { userStore } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { sendCodeToEmail, sendApproveCode } from '../../api/api';

import s from './AuthCode.module.css';
import Loader from '../../components/Loader/Loader';

export default function AuthCode() {
  const [timer, setTimer] = useState(60);
  const [number, setNumber] = useState('');
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const navigator = useNavigate();
  const { register, handleSubmit } = useForm();

  const user = userStore();

  const onSubmit = () => {
    setLoader(true);
    sendApproveCode(user.email, number)
      .then(() => {
        user.proveEmail();
      })
      .then(() => {
        navigator('/auth-success');
        user.proveEmail();
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message === 'Email-адрес уже существует') {
          navigator('/auth-success');
          user.proveEmail();
        } else {
          setNumber('');
          setError(true);
          setTimer(60);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);
  }, [timer]);

  useEffect(() => {
    if (number.length >= 4) {
      onSubmit();
    }
  }, [number]);

  return (
    <div className={s.authCode}>
      <Header />
      <NavigationBar />
      <div className={s.content}>
        <h1 className={s.title}>Введите код подтверждения</h1>
        <p className={s.subtitle}>
          Мы отправили его на E-mail {user.email}{' '}
          <Link to="/auth" className={s.link}>
            Изменить
          </Link>
        </p>
        <p className={`${s.subtitle} ${s.spam}`}>
          Если письмо не приходит, проверьте папку спам
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className={s.label}>
            Код подтверждения
            <input
              className={s.input}
              type="number"
              {...register('number', {
                required: true,
                maxLength: 4,
                minLength: 4,
              })}
              onChange={(e) => {
                setNumber(e.target.value.slice(0, 4));
              }}
              value={number}
            />
          </label>

          {error && (
            <p role="alert" className={s.errorText}>
              Введен некорректный код
              <br /> подтверждения
            </p>
          )}
          <button
            className={s.button}
            type="submit"
            disabled={timer <= 0 ? false : true}
            onClick={() => {
              sendCodeToEmail(user.email);
              setTimer(60);
            }}
          >
            {timer <= 0
              ? 'Отправить повторно'
              : `Отправить повторно - ${timer} c.`}
          </button>
        </form>
      </div>
      {loader && <Loader />}
    </div>
  );
}
