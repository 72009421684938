import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import FinalModal from '../../components/FinalModal/FinalModal';
import s from './Final.module.css';
import img from '../../images/final-bg.png';
import { getRewards } from '../../api/api';
import { linkToMain } from '../../api/api';

export default function Final() {
  const [modal, setModal] = useState(false);
  const [rewards, setRewards] = useState();

  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    getRewards()
      .then((responce) => {
        const rewards = responce?.data?.data;
        setRewards(rewards);
      })
      .then(() => {
        setModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className={s.final}>
      <Header />
      <NavigationBar backLink={localStorage.getItem('twa_main')} />

      <h2 className={s.title}>
        Спасибо за участие в «Звёздной Пятнице в Q CLUB»
      </h2>
      <p className={s.subtitle}>
        Вы можете найти коды на полученные предложения в разделе «Мои
        предложения». До новых встреч!
      </p>
      <img src={img} alt="Background" className={s.img} />
      {modal && <FinalModal onClose={closeModal} rewards={rewards} />}
    </div>
  );
}
