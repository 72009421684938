import React from 'react';
import s from './Loader.module.css';
import loader from '../../images/loader.svg';

export default function Loader() {
  return (
    <div className={s.loader}>
      <img src={loader} alt="Loader" className={s.img} />
    </div>
  );
}
