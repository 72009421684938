import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import star from '../../images/star-blue.png';
import s from './AuthSuccess.module.css';

export default function AuthSuccess() {
  const navigator = useNavigate();

  useEffect(() => {
    const spiceId = localStorage.getItem('spice_id');
    const spiceList = localStorage.getItem('spice_id_list');

    if (!spiceList?.includes(spiceId)) {
      if (spiceList) {
        const array = [];
        const fullArray = array.concat(spiceList);
        fullArray.push(spiceId);
        localStorage.setItem('spice_id_list', fullArray);
      } else {
        const array = [];
        array.push(spiceId);
        localStorage.setItem('spice_id_list', array);
      }
    }
  }, []);

  return (
    <div className={s.authSuccess}>
      <Header />
      <NavigationBar />
      <div className={s.content}>
        <img src={star} alt="Star" className={s.img} />
        <h2 className={s.title}>Супер! Ваш E-mail подтвержден</h2>
        <p className={s.subtitle}>Начинаем «Звёздную пятницу» </p>
        <button
          className={s.button}
          onClick={() => {
            navigator('/');
          }}
        >
          Начать
        </button>
      </div>
    </div>
  );
}
