import React, { useState } from 'react';
import s from './AnswerButton.module.css';

export default function AnswerButton({
  id,
  name,
  labelText,
  checked,
  onChange,
}) {
  return (
    <label className={`${s.button} ${checked ? s.checked : ''}`} htmlFor={id}>
      {labelText}
      <input
        type="radio"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        className={s.input}
      />
    </label>
  );
}
