import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Auth from './pages/Auth/Auth';
import AuthCode from './pages/AuthCode/AuthCode';
import AuthSuccess from './pages/AuthSuccess/AuthSuccess';
import AuthActual from './pages/AuthActual/AuthActual';
import Main from './pages/Main/Main';
import Final from './pages/Final/Final';
import { compareAsc } from 'date-fns';
import { finishDate } from './mockups/constants';
import { userStore } from './store/store';
import { SPICE_ID, emailInParams, NAMESPACE } from './api/api';

function App() {
  const navigator = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);

  const dataTwaOffers = searchParams.get('data_twa_my_offers');
  const dataTwaMain = searchParams.get('data_twa_main');

  if (emailInParams) {
    localStorage.setItem('email', emailInParams);
  } else if (searchParams.size > 0 && searchParams.size < 2) {
    localStorage.removeItem('email');
  }

  if (dataTwaOffers != null) {
    const linkToOffers =
      NAMESPACE === 'dev'
        ? `https://iqos-front-main.ktsdev.ru/tg?data=${dataTwaOffers}&stage=dev2`
        : `https://iqos.ru-prod2.kts.studio/tg?data=${dataTwaOffers}`;
    localStorage.setItem('twa_offer', linkToOffers);
  }

  if (dataTwaMain != null) {
    const linkToMain =
      NAMESPACE === 'dev'
        ? `https://iqos-front-main.ktsdev.ru/tg?data=${dataTwaMain}&stage=dev2`
        : `https://iqos.ru-prod2.kts.studio/tg/tg?data=${dataTwaMain}`;
    localStorage.setItem('twa_main', linkToMain);
  }

  useEffect(() => {
    const spiceId = localStorage.getItem('spice_id');
    const email = localStorage.getItem('email');

    if (spiceId && localStorage.getItem('spice_id_list')?.includes(spiceId)) {
      if (email) {
        navigator('/');
      } else {
        navigator('/auth');
      }
    } else {
      if (email) {
        navigator('/auth-actual');
      } else {
        navigator('/auth');
      }
    }
  }, []);

  useEffect(() => {}, []);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            compareAsc(new Date(), finishDate) > 0 ? <Final /> : <Main />
          }
        />
        {/* <Route path="/" element={<Main />} /> */}
        <Route path="/auth" element={<Auth />} />
        <Route path="/auth-code" element={<AuthCode />} />
        <Route path="/auth-success" element={<AuthSuccess />} />
        <Route path="/auth-actual" element={<AuthActual />} />
      </Routes>
    </div>
  );
}

export default App;
