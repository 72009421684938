import React from 'react';
import s from './NavigationBar.module.css';
import logo from '../../images/nav-logo.svg';
import { useNavigate } from 'react-router-dom';

export default function NavigationBar({ backLink }) {
  const navigate = useNavigate();

  return (
    <nav className={s.nav}>
      <button
        className={s.backButton}
        onClick={() => {
          if (backLink) {
            window.location.href = backLink;
          } else {
            navigate(-1);
          }
        }}
      ></button>
      <img src={logo} alt="Logo" className={s.logo} />
    </nav>
  );
}
