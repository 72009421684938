import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const userStore = create(
  persist(
    (set) => ({
      email: '',
      updateEmail: (email) => set(() => ({ email: email })),
      actualEmail: false,
      proveEmail: () => set({ actualEmail: true }),
      unproveEmail: () => set({ actualEmail: false }),
      rewards: [],
      updateRewards: (rewards) => set(() => ({ rewards: rewards })),
    }),
    {
      name: 'user-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

const dateStore = create(
  persist(
    (set) => ({
      startDate: '',
      finishDate: '',
      updateStartDate: (startDate) => set(() => ({ startDate: startDate })),
      updateFinishDate: (finishDate) => set(() => ({ finishDate: finishDate })),
    }),
    {
      name: 'date-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export { userStore, dateStore };
